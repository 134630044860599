import Css from "./style.module.scss";

import { Button, FormSelect, InputGroup, InputGroupAddon } from "shards-react";
import { DebounceInput, Form } from "lib/common";
import { FiFilter, FiX } from "react-icons/fi";
import { getInstitutionAccountsData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DateTimeRangePicker from "../DateTimeRangePicker";
import React, { useCallback, useEffect, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { TRANSACTION_TYPES: { WITHDRAW, DEPOSIT } } = DataConstants;

const TransactionsFilter = ({ disabled, compact, className, filters = {}, onChange }) => {
  const {
    month,
    fromDate = month ? moment(month).startOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
    toDate = month ? moment(month).endOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
    text,
    type,
    accountId
  } = filters;

  const { uiTexts } = useSelector(getTextsData);

  const institutionAccountsData = useSelector(getInstitutionAccountsData);

  const [{
    text: stateText = "",
    fromDate: stateFromDate = "",
    toDate: stateToDate = ""
  }, setState] = useState({ text, fromDate, toDate });

  const hasFilters = !!(fromDate || toDate || text || type || accountId);

  const handleFromDateInputChange = useCallback((value) => {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    setState((prev) => ({ ...prev, fromDate: formattedValue }));
  }, []);

  const handleToDateInputChange = useCallback((value) => {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    setState((prev) => ({ ...prev, toDate: formattedValue }));
  }, []);

  const handleToggleDateRangeButtonClick = useCallback(() => {
    const enabled = !!fromDate && !!toDate;

    const newState = {
      fromDate: enabled ? null : stateFromDate,
      toDate: enabled ? null : stateToDate
    };

    setState((prev) => ({ ...prev, ...newState }));
    onChange((prev) => ({ ...prev, ...newState, month: null }));
  }, [fromDate, toDate, stateFromDate, stateToDate, onChange]);

  const handleTextInputChange = useCallback(({ target: { value } }) => {
    setState((prev) => ({ ...prev, text: value }));
  }, []);

  const handleTextInputInputComplete = useCallback((value) => {
    onChange((prev) => ({ ...prev, text: value }));
  }, [onChange]);

  const handleToggleTextButtonClick = useCallback(() => {
    setState((prev) => ({ ...prev, text: "" }));
    onChange((prev) => ({ ...prev, text: "" }));
  }, [onChange]);

  const handleTypeSelectChange = useCallback(({ target: { value } }) => {
    onChange((prev) => ({ ...prev, type: value }));
  }, [onChange]);

  const handleToggleTypeButtonClick = useCallback(() => {
    onChange((prev) => ({ ...prev, type: "" }));
  }, [onChange]);

  const handleAccountIdSelectChange = useCallback(({ target: { value } }) => {
    onChange((prev) => ({ ...prev, accountId: value }));
  }, [onChange]);

  const handleToggleAccountIdButtonClick = useCallback(() => {
    onChange((prev) => ({ ...prev, accountId: "" }));
  }, [onChange]);

  const handleResetButtonClick = useCallback(() => {
    setState({ text: "", fromDate: "", toDate: "" });
    onChange(() => ({ fromDate: null, toDate: null, text: null, type: null, accountId: null, month: null }));
  }, [onChange]);

  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      setState((prev) => {
        return {
          ...prev,
          fromDate: filters.fromDate,
          toDate: filters.toDate
        };
      });
    }
  }, [filters.fromDate, filters.toDate]);

  useEffect(() => {
    if (stateFromDate && stateToDate) {
      return Utils.setTimeout(() => {
        onChange((prev) => ({ ...prev, fromDate: stateFromDate, toDate: stateToDate, month: null }));
      }, Constants.TEXT_FIELD_DEBOUNCE_TIMEOUT);
    }

    return () => {};
  }, [onChange, stateFromDate, stateToDate]);

  return (
    <div className={classNames(Css.transactionsFilter, className)}>
      <Form>
        <DateTimeRangePicker
          disabled={disabled}
          startDate={stateFromDate}
          endDate={stateToDate}
          fromDate={stateFromDate}
          toDate={stateToDate}
          onFromDateChange={handleFromDateInputChange}
          onToDateChange={handleToDateInputChange}
          onToggleClick={handleToggleDateRangeButtonClick} />
        <InputGroup>
          <DebounceInput
            disabled={disabled}
            value={stateText || ""}
            placeholder={uiTexts.searchText}
            onChange={handleTextInputChange}
            onInputComplete={handleTextInputInputComplete} />
          <InputGroupAddon type="append">
            <Button
              theme={text ? "primary" : "secondary"}
              disabled={disabled || !stateText}
              onClick={handleToggleTextButtonClick}>
              <FiFilter />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <InputGroup>
          <FormSelect
            disabled={disabled}
            placeholder={type ? undefined : ""}
            value={type || ""}
            onChange={handleTypeSelectChange}>
            {!type && <option value="">{uiTexts.type}</option>}
            <option value={WITHDRAW}>{uiTexts.expenses}</option>
            <option value={DEPOSIT}>{uiTexts.income}</option>
            {type && <option value="">{uiTexts.all}</option>}
          </FormSelect>
          <InputGroupAddon type="append">
            <Button
              theme={type ? "primary" : "secondary"}
              disabled={disabled || !type}
              onClick={handleToggleTypeButtonClick}>
              <FiFilter />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <InputGroup>
          <FormSelect
            disabled={disabled}
            placeholder={accountId ? undefined : ""}
            value={accountId || ""}
            onChange={handleAccountIdSelectChange}>
            {!accountId && <option value="">{uiTexts.account}</option>}
            {institutionAccountsData
              .map(({ id, name, accountNumber }) => <option key={id} value={id}>{name || accountNumber}</option>)}
            {accountId && <option value="">{uiTexts.all}</option>}
          </FormSelect>
          <InputGroupAddon type="append">
            <Button
              theme={accountId ? "primary" : "secondary"}
              disabled={disabled || !accountId}
              onClick={handleToggleAccountIdButtonClick}>
              <FiFilter />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {hasFilters && (
          <InputGroup className={Css.resetButtonGroup}>
            <Button theme="danger" outline onClick={handleResetButtonClick}>
              <FiX />
              {!compact && <span>{uiTexts.resetFilters}</span>}
            </Button>
          </InputGroup>
        )}
      </Form>
    </div>
  );
};

export default React.memo(TransactionsFilter);
