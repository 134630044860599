import Css from "./style.module.scss";

import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import ButtonRadioGroup from "lib/common/ButtonRadioGroup";
import React, { PureComponent } from "react";

import StatusFilterCounter from "./lib/StatusFilterCounter";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class StatusFilter extends PureComponent {
  get optionsList() {
    const { textsData, stats, statusesList } = this.props;

    return statusesList.map((statusData) => {
      const { iconComponent: Icon, labelLangId, value } = statusData;

      const label = textsData.uiTexts[labelLangId];

      const totalCount = value ? stats[value] : stats.total;

      return {
        value,
        label: <>
          <Icon title={label} />
          <span title={label}>{label}</span>
          <StatusFilterCounter loading={totalCount === undefined} value={totalCount} status={value} />
        </>
      };
    });
  }

  render() {
    const { value, disabled, onChange } = this.props;

    return (
      <ButtonRadioGroup
        outline
        size="sm"
        theme="light"
        key={value}
        className={Css.statusFilter}
        initialValue={value}
        disabled={disabled}
        options={this.optionsList}
        onChange={onChange} />
    );
  }
}

export default StatusFilter;
