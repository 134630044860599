import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AutoTooltip } from "lib/common";
import { FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { FiCreditCard, FiUser } from "react-icons/fi";
import { FormCheckbox } from "shards-react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo } from "react";
import TableNew, { TableCell, TableHead, TableRow } from "lib/common/TableNew";
import Utils from "utils/Utils";
import moment from "moment";

const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const TimestampCell = React.memo(({ value }) => {
  return (
    <AutoTooltip active={!!value}>
      {value
        ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT)
        : Constants.EMPTY_PLACEHOLDER}
    </AutoTooltip>
  );
});

const AccountCell = React.memo(({ value }) => {
  return (
    <AutoTooltip active={!!value}>
      {value
        ? <>
          <FiCreditCard />
          <span>{value}</span>
        </>
        : Constants.EMPTY_PLACEHOLDER}
    </AutoTooltip>
  );
});

const AddressCell = React.memo(({ name }) => {
  return (
    <AutoTooltip active={!!name}>
      <span>
        {name ? <FiUser /> : null}
        <span>{name || Constants.EMPTY_PLACEHOLDER}</span>
      </span>
    </AutoTooltip>
  );
});

const ExtraDataCell = React.memo(({ description, extraData = {} }) => {
  const extraValues = useMemo(() => {
    return Object.entries(extraData)
      .map(([field, fieldValue]) => fieldValue && `${field}: ${fieldValue}`)
      .filter((extraText) => extraText)
      .join(", ");
  }, [extraData]);

  const content = (extraValues.length ? `${extraValues}${description ? ", " : ""}` : "") + (description || "");

  return <AutoTooltip active={!!content}>{content || Constants.EMPTY_PLACEHOLDER}</AutoTooltip>;
});

const AmountCell = React.memo(({ value, type, currency }) => {
  const withdrawTransaction = type === WITHDRAW;

  const content = value !== undefined && Utils.toMoneyString(value, currency);

  return (
    <AutoTooltip
      active={value !== undefined}
      className={type && value ? CommonCss.coloredNumber : ""}
      data-negative={withdrawTransaction ? "" : undefined}>
      {content}
    </AutoTooltip>
  );
});

const NotificationsCell = React.memo(({ transactionId, allCommentsCount, unreadCommentsCount, onClick }) => {
  const { uiTexts } = useSelector(getTextsData);

  const handleClick = useCallback(() => {
    onClick(transactionId);
  }, [onClick, transactionId]);

  return (
    <span
      title={allCommentsCount || uiTexts.addComment}
      data-id={transactionId}
      className={unreadCommentsCount ? CommonCss.negativeText : undefined}
      onClick={handleClick}>
      {allCommentsCount
        ? <>
          <FaCommentDots />
          <span><b>{allCommentsCount}</b></span>
        </>
        : <FaRegCommentDots />}
    </span>
  );
});

const TransactionsTable = (props) => {
  const {
    disabled,
    data,
    page,
    pageSize,
    sortings,
    totalDataCount,
    selectedItems,
    onFetchData,
    onItemsSelect,
    onTransactionCommentsOpen
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const pagesCount = (totalDataCount && pageSize) ? Math.ceil(totalDataCount / pageSize) : 0;

  const handleNotificationsLinkClick = useCallback((id) => {
    onTransactionCommentsOpen(id);
  }, [onTransactionCommentsOpen]);

  const handleSortChange = useCallback(({ sortBy, sortOrder }) => {
    onFetchData({ page, pages: pagesCount, pageSize, sorted: [{ id: sortBy, desc: sortOrder === "desc" }] });
  }, [onFetchData, page, pageSize, pagesCount]);

  const handlePageChange = useCallback((newValue) => {
    onFetchData({ page: newValue - 1, pages: pagesCount, pageSize, sorted: [sortings] });
  }, [onFetchData, pageSize, pagesCount, sortings]);

  const handlePageSizeChange = useCallback((newValue) => {
    onFetchData({ page: 0, pages: pagesCount, pageSize: newValue, sorted: [sortings] });
  }, [onFetchData, pagesCount, sortings]);

  const handleHeaderCheckBoxChange = useCallback(({ target: { checked } }) => {
    onItemsSelect(checked ? data.map(({ id }) => id) : []);
  }, [data, onItemsSelect]);

  const handleCheckBoxChange = useCallback(({ target: { checked, dataset: { id } } }) => {
    onItemsSelect(checked ? [...selectedItems, id] : selectedItems.filter((selectedId) => selectedId !== id));
  }, [selectedItems, onItemsSelect]);

  useEffect(() => {
    if (selectedItems.length && data.every(({ id }) => !selectedItems.includes(id))) {
      onItemsSelect([]);
    }
  }, [data, onItemsSelect, selectedItems]);

  return (
    <TableNew
      disabled={disabled}
      className={Css.table}
      count={totalDataCount}
      page={1 + page}
      pageSize={pageSize}
      sortBy={sortings?.id}
      sortOrder={sortings?.desc ? "desc" : "asc"}
      onSortChange={handleSortChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}>
      <TableRow>
        <TableHead className={Css.checkboxCell}>
          <FormCheckbox
            checked={data.length && data.every(({ id }) => selectedItems.includes(id))}
            disabled={disabled || !data.length}
            onChange={handleHeaderCheckBoxChange} />
        </TableHead>
        <TableHead accessor="timestamp" className={Css.dateCell}>{uiTexts.date}</TableHead>
        <TableHead accessor="account" className={Css.accountCell}>{uiTexts.account}</TableHead>
        <TableHead accessor="address" className={Css.addressCell}>{uiTexts.payee}</TableHead>
        <TableHead className={Css.extraCell}>{uiTexts.extraInformation}</TableHead>
        <TableHead accessor="amount" className={Css.amountCell}>{uiTexts.total}</TableHead>
        <TableHead className={Css.notificationCell}>{uiTexts.comments}</TableHead>
      </TableRow>
      {data.map((item) => {
        const {
          id,
          timestamp,
          account,
          address,
          extra,
          description,
          amount = 0,
          type,
          currency,
          comments = {}
        } = item;

        return (
          <TableRow key={id}>
            <TableCell className={Css.checkboxCell}>
              <FormCheckbox
                data-id={id}
                checked={selectedItems.includes(id)}
                disabled={disabled}
                onChange={handleCheckBoxChange} />
            </TableCell>
            <TableCell className={Css.dateCell}>
              <TimestampCell value={timestamp} />
            </TableCell>
            <TableCell className={Css.accountCell}>
              <AccountCell value={account.name || account.accountNumber} />
            </TableCell>
            <TableCell className={Css.addressCell}>
              <AddressCell name={address.name} />
            </TableCell>
            <TableCell className={Css.extraCell}>
              <ExtraDataCell extraData={extra} description={description} />
            </TableCell>
            <TableCell className={Css.amountCell}>
              <AmountCell value={amount} type={type} currency={currency} />
            </TableCell>
            <TableCell className={Css.notificationCell}>
              <NotificationsCell
                transactionId={id}
                allCommentsCount={comments.all}
                unreadCommentsCount={comments.unread}
                onClick={handleNotificationsLinkClick} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableNew>
  );
};

export default React.memo(TransactionsTable);
