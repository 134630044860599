import {
  FiExternalLink,
  FiList,
  FiMinusCircle,
  FiRepeat
} from "react-icons/fi";
import { memoize } from "decko";
import DataConstants from "const/DataConstants";

export default class TransactionsStatuses {
  @memoize
  static getCzStatusData(status) {
    const { TO_RECONCILE, EXPORTED, EXCLUDED } = DataConstants.STATUSES;

    const statusesData = {
      [TO_RECONCILE]: {
        value: TO_RECONCILE,
        canBeAssigned: true,
        iconComponent: FiRepeat,
        labelLangId: "toExport"
      },
      [EXCLUDED]: {
        value: EXCLUDED,
        canBeAssigned: true,
        iconComponent: FiMinusCircle,
        labelLangId: "excluded"
      },
      [EXPORTED]: {
        value: EXPORTED,
        iconComponent: FiExternalLink,
        labelLangId: "exported"
      },
      [null]: {
        value: null,
        iconComponent: FiList,
        labelLangId: "all"
      }
    };

    return status === undefined ? statusesData : statusesData[status];
  }
}
