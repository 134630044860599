import Css from "./style.module.scss";

import { DropDownSelect } from "lib/common";
import { FiChevronsRight } from "react-icons/fi";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class ChangeStatusSelector extends PureComponent {
  get optionsList() {
    const { textsData: { uiTexts }, statusesList, excludedStatuses = [] } = this.props;

    const assignableStatuses = statusesList.filter(
      ({ value, canBeAssigned }) => !excludedStatuses.includes(value) && canBeAssigned
    );

    return assignableStatuses.map(({ value, labelLangId, iconComponent: Icon }) => ({
      value,
      label: <><Icon data-status={value} /><span>{uiTexts[labelLangId]}</span></>
    }));
  }

  render() {
    const { textsData: { uiTexts }, disabled, onChange } = this.props;

    return (
      <DropDownSelect
        caret
        right
        outline
        theme="secondary"
        size="sm"
        className={Css.changeStatusSelector}
        toggleContent={<><FiChevronsRight /><span>{`${uiTexts.moveThemTo}...`}</span></>}
        disabled={disabled}
        options={this.optionsList}
        onChange={onChange} />
    );
  }
}

export default ChangeStatusSelector;
