import Css from "./style.module.scss";

import { Badge } from "lib/common";
import { bind } from "decko";
import React, { PureComponent } from "react";

export default class StatusFilterCounter extends PureComponent {
  state = { animation: false };

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value && prevProps.value !== value && !prevProps.loading) {
      this.setState({ animation: true });
    }
  }

  @bind
  handleAnimationEnd() {
    this.setState({ animation: false });
  }

  render() {
    const { loading, value, status } = this.props;

    return (
      <Badge
        title={value}
        data-status={status}
        data-zero={!loading && value ? undefined : ""}
        data-animation={!loading && this.state.animation ? "" : undefined}
        value={loading ? "..." : value}
        className={Css.statusFilterCounter}
        onAnimationEnd={this.handleAnimationEnd} />
    );
  }
}
